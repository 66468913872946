.sidebar {
  bottom: 0;
  position: fixed;
  left: 0;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.iconContainer {
  margin-top: 20px;
}

.iconSVG {
  * {
    width: 30px;
    height: 30px;
    fill: #e94f37;
  }
}

.iconSVG:hover {
  * {
    fill: #b82a14;
  }
}

@media only screen and (max-width: 1150px) {
  .sidebar {
    display: none;
  }
}
