.projectsContainer {
  width: 100%;
  cursor: grab;
}

.projectsContainer:active {
  cursor: grabbing;
}

.sectionTitle {
  font-family: interSemiBold;
  color: #f6f7eb;
  font-size: 34px;
}

.projectsContainer > div > div {
  height: auto;
}
