.aboutWrapper {
  h1 {
    font-family: interSemiBold;
    color: #f6f7eb;
    font-size: 34px;
    margin: 50px 0 20px;
  }

  p {
    font-family: interRegular;
    color: #f6f7eb;
    font-size: 18px;
  }
}
