.iconContainer {
  margin-top: 25px !important;

  div {
    width: fit-content;
    a {
      display: flex;
      align-items: center;

      .iconSVG {
        margin-right: 20px;
      }

      span {
        color: #f6f7eb;
      }
    }
  }
}

.iconSVG {
  * {
    width: 30px;
    height: 30px;
    fill: #e94f37;
  }
}

.iconSVG:hover {
  * {
    fill: #b82a14;
  }
}
