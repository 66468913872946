.projectCard {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 10px;
  padding: 30px;
  color: #f6f7eb;
  border: 2px solid #e94f37;
}

.projectCardTitle {
  text-align: center;
  font-family: interSemiBold;
  font-size: 24px;

  h3 {
    color: #f6f7eb;
  }
}

.projectCardSubTitle {
  text-align: center;
  font-family: interRegular;
  font-size: 22px;

  h4,
  h5 {
    color: #f6f7eb;
    margin: 0;
  }
}

.projectCardLinks {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  font-size: 36px;

  a {
    color: #e94f37;
  }

  a:hover {
    color: #b82a14;
  }
}

.projectCardDescriptionTitle {
  text-align: center;
  font-family: interSemiBold;
  font-size: 24px;
  margin-top: 20px;

  h4 {
    color: #f6f7eb;
  }
}

.projectCardDescription {
  p {
    font-family: interRegular;
    font-size: 18px;
  }
}

.projectCardSkillsTitle {
  text-align: center;
  font-family: interSemiBold;
  font-size: 24px;
  margin-top: 20px;

  h4 {
    color: #f6f7eb;
  }
}

.projectCardSkillsList {
  li {
    font-family: interRegular;
    font-size: 20px;
  }
}
