.componentWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.imageWrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;

  img {
    width: 100%;
  }
}

.nameAndTextsWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  width: 60%;

  h1 {
    font-family: interSemiBold;
    color: #f6f7eb;
    font-size: 40px;
    margin: 0;
  }
}

.loop {
  margin-top: 30px;
  font-size: 25px;
  font-family: interRegular;
}

@media only screen and (max-width: 460px) {
  .sidebar,
  .imageWrapper {
    display: none;
  }

  .componentWrapper {
    flex-direction: column;
    align-items: center;
  }

  .nameAndTextsWrapper {
    width: 100%;
    margin: 0;

    h1 {
      margin: 20px 0 0;
      text-align: center;
      color: #f6f7eb;
      font-size: 40px;
    }
  }

  .loopWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1150px) {
  .componentWrapper {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .nameAndTextsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 30px 0 0 0;
  }
}
