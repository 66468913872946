.hashlinkNav {
  display: flex;
  justify-content: space-evenly;
  background-color: #393e41;
  border: none;

  a {
    color: #f6f7eb;
    font-size: 1.5rem;
  }

  a:hover {
    color: #e94f37;
  }
}

.imageWrapper {
  width: 260px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;

  img {
    width: 100%;
  }
}

.customDrawer {
  div:nth-child(2) {
    div {
      background-color: #393e41;

      div {
        div {
          background-color: #393e41;
          display: flex;
          flex-direction: column;
          align-items: center;
          * {
            fill: #e94f37;
          }

          div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

.hashLinkForSmallScreens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  a {
    color: #f6f7eb;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  a:hover {
    color: #e94f37;
  }
}

.svgMenuButton {
  background-color: #e94f37;
  border-color: #e94f37;
  fill: #e94f37;
}

.svgMenuButton:hover {
  background-color: #b82a14;
  border-color: #b82a14;
  fill: #b82a14;
}

.contactModal {
  * {
    font-size: 20px;
    background-color: #393e41;
    border-color: #f6f7eb;
  }

  div {
    div {
      border: none;

      div {
        color: #f6f7eb;
        font-size: 30px;
        margin-top: 10px;
      }
    }
  }

  .iconContainerWrapper {
    margin-top: -20px;
  }
}

@media (min-width: 750px) {
  .svgMenuButton {
    margin-top: 20px;
    display: none;
  }
  .menuForLargeScreen {
    margin-top: 10px;
    right: 10px;
  }
}

@media (max-width: 750px) {
  .menuForLargeScreen {
    margin-top: 10px;
    display: none;
  }

  .svgMenuButton {
    position: absolute;
    margin-top: 20px;
    right: 20px;
  }

  .imageWrapper {
    img {
      width: 80%;
    }
  }
}
