.appContainer {
  padding: 100px 20px 20px 90px;
}

@media only screen and (max-width: 1150px) {
  .appContainer {
    padding: 100px 40px 20px !important;
  }
}

@media only screen and (max-width: 460px) {
  .appContainer {
    padding: 100px 20px 20px !important;
  }
}
